import { Injectable } from "@angular/core";

import { OutsideMessageEnum } from "@enums/OutsideMessageEnum";


@Injectable({
  providedIn: 'root'
})
export class OutsideMessageServices {

  sendMessageToWebParent(data) {
    window?.parent?.postMessage(data, "*");
  }

  sendMessageToIOSParent(data) {
    (window as any)?.webkit?.messageHandlers?.gameHandler?.postMessage(data);
  }

  sendMessageToAndroidParent(data) {
    if ((window as any)?.android?.postMessage && typeof (window as any).android.postMessage === 'function') {
      (window as any)?.android?.postMessage(data);
    }
  }

  sessionExpired() {
    this.sendMessagePlatformSensitive({ type: OutsideMessageEnum.sessionExpired });
  }

  userLoginRequiredDeviceSensitive() {
    this.sendMessagePlatformSensitive({ type: OutsideMessageEnum.userLoginRequired });
  }

  sendMessagePlatformSensitive(message) {
    if ((window as any).android) {
      // Android
      if (message.type === OutsideMessageEnum.sessionExpired) {
        (window as any).android?.sessionExpired();
      } else {
        this.sendMessageToAndroidParent(message);
      }
    } else if ((window as any)?.webkit?.messageHandlers?.gameHandler) {
      // iOS
      this.sendMessageToIOSParent(message);
    } else if (window.parent) {
      // Web
      this.sendMessageToWebParent(message);
    }
  }

}
