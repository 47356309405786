import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, of, Subject, take } from "rxjs";
import {
  switchMap,
} from "rxjs/operators";

import { UriConfig } from "@app/app.config";
import { BracketTypeEnum } from "@enums/BracketTypeEnum";
import { GameStatusEnum } from "@enums/GameStatusEnum";
import { BracketGameModel } from "@models/BracketGameModel";
import { RoundSetDetailsModel } from "@models/RoundItemDetailsModel";
import { StreakDetailModel } from "@models/StreakDetailModel";

import { CmsContentService } from "./cms-content.service";


@Injectable({
  providedIn: 'root'
})
export class BracketGameService {

  round$ = new BehaviorSubject<BracketGameModel>(undefined);

  roundsSet$ = new BehaviorSubject<RoundSetDetailsModel | StreakDetailModel>(undefined);

  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    protected http: HttpClient,
    private uriConfig: UriConfig,
    private cmsContentService: CmsContentService,
  ) {}

  prepareRoundForBracket(cmsContent, round: BracketGameModel) {
    const roundSet = { ...round };

    round['gameButtonText'] = this.getBracketButtonText(round, cmsContent);
    round['gameButtonColor'] = this.getBracketButtonColor(round, cmsContent);
    round['gameButtonTextColor'] = this.getButtonTextBracketColor(round, cmsContent);
    round['gameButtonBorderColor'] = this.getButtonBorderBracketColor(round, cmsContent);


    return roundSet;
  }

  isRoundGroupActive(groupStatus: GameStatusEnum, knockoutStatus: GameStatusEnum) {
    return groupStatus === GameStatusEnum.PENDING || groupStatus === GameStatusEnum.OPENED || groupStatus === GameStatusEnum.CLOSED || (groupStatus === GameStatusEnum.COMPLETED && knockoutStatus === GameStatusEnum.DRAFT);
  }

  setActiveRound(bracket: BracketGameModel) {
    const group = bracket[BracketTypeEnum.GROUP];
    const knockout = bracket[BracketTypeEnum.KNOCKOUT];

    if (group.status === GameStatusEnum.DRAFT && knockout.status === GameStatusEnum.DRAFT) {
      bracket.activeType = null;
    } else if (this.isRoundGroupActive(group.status, knockout.status)) {
      bracket.activeType = BracketTypeEnum.GROUP;
    } else  {
      bracket.activeType = BracketTypeEnum.KNOCKOUT;
    }

    return bracket;
  }

  reloadRound() {
    this.getDefaultRounds().pipe(
      take(1)
    ).subscribe();
  }

  getDefaultRounds() {
    return this.http.get<BracketGameModel>(this.uriConfig.rounds + '/bracket/default').pipe(
      switchMap((round: BracketGameModel) => {
        return combineLatest([
          this.cmsContentService.cmsContent$,
          of(round)
        ])
      }),
      switchMap(([cmsContent, rounds]) => {
        if (!rounds) {
          return of(null);
        }

        rounds = this.setActiveRound(rounds);

        if (rounds.activeType) {
          this.roundsSet$.next(this.prepareRoundForBracket(cmsContent, rounds));
        }
        this.round$.next(rounds);

        return of(rounds);
      })
    )
  }


  isBracketNotOpened(game: BracketGameModel) {
    return game[game.activeType].status === GameStatusEnum.PENDING;
  }

  isBracketOpenedAndNotPlayedGroups(game: BracketGameModel) {
    return game[BracketTypeEnum.GROUP].status === GameStatusEnum.OPENED && !game[BracketTypeEnum.GROUP].hasPlayed && game.activeType === BracketTypeEnum.GROUP;
  }

  isBracketOpenedAndNotPlayedKnockouts(game: BracketGameModel) {
    return game[BracketTypeEnum.KNOCKOUT].status === GameStatusEnum.OPENED && !game[BracketTypeEnum.KNOCKOUT].hasPlayed && game.activeType === BracketTypeEnum.KNOCKOUT;
  }

  isBracketOpenedAndPlayedGroups(game: BracketGameModel) {
    return game[BracketTypeEnum.GROUP].status == GameStatusEnum.OPENED && game[BracketTypeEnum.GROUP].hasPlayed && game.activeType === BracketTypeEnum.GROUP;
  }

  isBracketOpenedAndPlayedKnockouts(game: BracketGameModel) {
    return game[BracketTypeEnum.KNOCKOUT].status === GameStatusEnum.OPENED && game[BracketTypeEnum.KNOCKOUT].hasPlayed && game.activeType === BracketTypeEnum.KNOCKOUT;
  }

  isBracketClosedAndPlayedKnockouts(game: BracketGameModel) {
    return game[BracketTypeEnum.KNOCKOUT].status === GameStatusEnum.CLOSED && game[BracketTypeEnum.KNOCKOUT].hasPlayed && game.activeType === BracketTypeEnum.KNOCKOUT;
  }

  isBracketCompletedAndPlayedKnockouts(game: BracketGameModel) {
    return game[BracketTypeEnum.KNOCKOUT].status == GameStatusEnum.COMPLETED && game[BracketTypeEnum.KNOCKOUT].hasPlayed && game.activeType === BracketTypeEnum.KNOCKOUT;
  }

  isBracketClosedGroups(game: BracketGameModel) {
    return (game[BracketTypeEnum.GROUP].status == GameStatusEnum.CLOSED && game[BracketTypeEnum.GROUP].hasPlayed && game.activeType === BracketTypeEnum.GROUP)
      || (this.isRoundClosed(game[BracketTypeEnum.KNOCKOUT].status) && game[BracketTypeEnum.GROUP].status === GameStatusEnum.COMPLETED && game[BracketTypeEnum.GROUP].hasPlayed && game.activeType === BracketTypeEnum.KNOCKOUT);
  }


  isBracketCompletedGroups(game: BracketGameModel) {
    return game[BracketTypeEnum.GROUP].status == GameStatusEnum.COMPLETED && game[BracketTypeEnum.GROUP].hasPlayed && game.activeType === BracketTypeEnum.GROUP
  }

  isBracketNotPlayedAndClosedGroups(game: BracketGameModel) {
    return this.isRoundClosed(game[BracketTypeEnum.GROUP].status) && !game[BracketTypeEnum.GROUP].hasPlayed && game.activeType === BracketTypeEnum.GROUP;
  }

  isBracketNotPlayedAndClosedKnockouts(game: BracketGameModel) {
    return this.isRoundClosed(game[BracketTypeEnum.KNOCKOUT].status) && !game[BracketTypeEnum.GROUP].hasPlayed && !game[BracketTypeEnum.KNOCKOUT].hasPlayed && game.activeType === BracketTypeEnum.KNOCKOUT;
  }

  isRoundClosed(status: GameStatusEnum) {
    return status === GameStatusEnum.CLOSED || status === GameStatusEnum.COMPLETED || status === GameStatusEnum.RESULT_PROCESSING;
  }

  getBracketButtonText(game, cmsContent) {
    if (this.isBracketNotOpened(game)) {
      return cmsContent.text_content['iframe-v3-bracket-landing-opening-soon'];
    }

    if (this.isBracketOpenedAndNotPlayedGroups(game)) {
      return cmsContent.text_content['iframe-v3-bracket-landing-play-for-free'];
    }

    if (this.isBracketOpenedAndPlayedGroups(game)) {
      return cmsContent.text_content['iframe-v3-bracket-landing-edit-group-picks'];
    }

    if (this.isBracketClosedGroups(game) || this.isBracketClosedAndPlayedKnockouts(game)) {
      return cmsContent.text_content['iframe-v3-bracket-landing-view-predictions'];
    }


    if (this.isBracketCompletedGroups(game)) {
      return cmsContent.text_content['iframe-v3-bracket-landing-view-group-results'];
    }

    if (this.isBracketNotPlayedAndClosedGroups(game) || this.isBracketNotPlayedAndClosedKnockouts(game)) {
      return cmsContent.text_content['iframe-v3-bracket-landing-no-selections'];
    }

    if (this.isBracketOpenedAndNotPlayedKnockouts(game)) {
      return cmsContent.text_content['iframe-v3-bracket-go-to-knockouts'];
    }

    if (this.isBracketOpenedAndPlayedKnockouts(game)) {
      return cmsContent.text_content['iframe-v3-bracket-edit-knockouts'];
    }

    if (this.isBracketCompletedAndPlayedKnockouts(game)) {
      return cmsContent.text_content['iframe-v3-bracket-landing-view-knockout-results'];
    }

    return cmsContent.text_content['iframe-v3-bracket-landing-view-predictions'];
  }

  getBracketButtonColor(game, cmsContent) {
    if (this.isBracketNotOpened(game) || this.isBracketNotPlayedAndClosedGroups(game) || this.isBracketNotPlayedAndClosedKnockouts(game)) {
      return cmsContent.colour_content['iframe-v3-bracket-landing-inactive-button-color'];
    }

    if (this.isBracketOpenedAndNotPlayedGroups(game)
      || this.isBracketOpenedAndPlayedGroups(game)
      || this.isBracketCompletedGroups(game)
      || this.isBracketOpenedAndNotPlayedKnockouts(game)
      || this.isBracketOpenedAndPlayedKnockouts(game)
      || this.isBracketCompletedAndPlayedKnockouts(game)) {
      return cmsContent.colour_content['iframe-v3-bracket-landing-active-button-color'];
    }

    if (this.isBracketClosedGroups(game) || this.isBracketClosedAndPlayedKnockouts(game)) {
      return cmsContent.colour_content['iframe-v3-bracket-landing-view-predictions-active-button-color'];
    }


    return cmsContent.colour_content['iframe-v3-bracket-landing-view-predictions-active-button-color'];
  }

  getButtonTextBracketColor(game, cmsContent) {

    if (this.isBracketNotOpened(game) || this.isBracketNotPlayedAndClosedGroups(game) || this.isBracketNotPlayedAndClosedKnockouts(game)) {
      return cmsContent.colour_content['iframe-v3-bracket-landing-inactive-text-color']
    }

    if (this.isBracketOpenedAndNotPlayedGroups(game)
      || this.isBracketOpenedAndPlayedGroups(game)
      || this.isBracketCompletedGroups(game)
      || this.isBracketOpenedAndNotPlayedKnockouts(game)
      || this.isBracketOpenedAndPlayedKnockouts(game)
      || this.isBracketCompletedAndPlayedKnockouts(game)
    ) {
      return cmsContent.colour_content['iframe-v3-bracket-landing-active-text-color'];
    }

    if (this.isBracketClosedGroups(game) || this.isBracketClosedAndPlayedKnockouts(game)) {
      return cmsContent.colour_content['iframe-v3-bracket-landing-view-predictions-text-color'];
    }

    return cmsContent.colour_content['iframe-v3-bracket-landing-view-predictions-text-color'];
  }

  getButtonBorderBracketColor(game, cmsContent) {
    if (this.isBracketNotOpened(game) || this.isBracketNotPlayedAndClosedGroups(game) || this.isBracketNotPlayedAndClosedKnockouts(game)) {
      return cmsContent.colour_content['iframe-v3-bracket-landing-inactive-border-color']
    }

    return ''
  }

}
