import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ScrollService {

  scrollPosition: number;

  scrollToPosition$ = new BehaviorSubject(false);

  questionScroll$ = new BehaviorSubject(0);

  infiniteScrollAction$ = new Subject();

  upsellSelectedIndex$ = new BehaviorSubject<number>(1);

  constructor() { }
}
