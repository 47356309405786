import { NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TimerService } from "@common-services/timer/timer.service";
import { CmsContentService } from "@services/cms-content.service";

@Component({
    selector: 'hun-timer',
    templateUrl: './timer.component.html',
    styleUrls: ['./timer.component.scss'],
    providers: [TimerService],
    standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgTemplateOutlet, AsyncPipe]
})
export class TimerComponent {
  @Input() set date(value: string) {
    this.timerService.date = value;
  }

  @Input() set isCloseTime(value: boolean) {
    this.timerService.isCloseTime = value
  };


  @Input() color: string;

  @Input() showMinutes: boolean;
  @Input() showSeconds = false;

  constructor(
    public timerService: TimerService,
    public cmsContentService: CmsContentService
  ) {
  }
}
