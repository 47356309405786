import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, of, throwError } from "rxjs";
import { catchError, filter, switchMap } from "rxjs/operators";

import { DecorElementsEnum } from "@enums/DecorElementsEnum";
import { SettingsConfigDetail } from "@models/SettingsConfigDetail";
import { SettingAvailabilityEnum } from 'src/app/app-common/enums/SettingAvailabilityEnum';
import { UriConfig } from 'src/app/app.config';


import { AuthService } from "./auth.service";
import { LocalStorageService } from "./local-storage.service";
import { MaintenanceControlService } from "./maintenance-control.service";

@Injectable({
  providedIn: 'root'
})
export class SaasSetingsService {

  settingConfigArray$ = new BehaviorSubject<SettingsConfigDetail[]>([]);

  isMultipleQuestionsOn1Page$: Observable<boolean>;

  isLeaderboardToastMessage$: Observable<boolean>;

  isWinnersMessageDisplayed$: Observable<boolean>;

  bigPrizeAmount$: Observable<boolean>;

  isHideQuestionText$: Observable<boolean>;

  isShowUpsell$: Observable<boolean>;

  currencyAfterPrize$: Observable<boolean>;

  amountSeparator$: Observable<string>;

  decorElements$: Observable<DecorElementsEnum>;

  canQuestionsAfterEventStart$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  landingPrizeAnimation$: Observable<boolean>;

  constructor(
    protected http: HttpClient,
    private uriConfig: UriConfig,
    private localStorageService: LocalStorageService,
    private maintenanceControlService: MaintenanceControlService,
    private authService: AuthService
  ) { }

  getSettingsList(): Observable<SettingsConfigDetail[]> {
    let params = new HttpParams();
    params = params.append('availability', SettingAvailabilityEnum.B2C);
    params = params.append('availability', SettingAvailabilityEnum.BOTH);

    return this.http.get<SettingsConfigDetail[]>(this.uriConfig.settings + '/b2c', { params });
  }


  loadSettings(hashResponse:  string): Observable<SettingsConfigDetail[]> {
    const hash = this.localStorageService.getSettingsHash();
    const savedSettingConfig = this.localStorageService.getSettingsConfig();
    if (savedSettingConfig && hash === hashResponse) {
      this.processSetting(savedSettingConfig);
      return of(savedSettingConfig);
    } else {
      return this.getSettingsList().pipe(
        switchMap((settings: SettingsConfigDetail[]) => {
          this.localStorageService.setSettingsHash(hashResponse);
          this.localStorageService.setSettingsConfig(settings)
          this.processSetting(settings);
          return of(settings);
        }),
        catchError(error => {
          return throwError(error)
        })
      );
    }
  }

  processSetting(config) {
    this.settingConfigArray$.next(config);
    this.checkMaintenanceMode(config);
    this.checkBannedUserInform(config);
    this.checkQuestionView(config);
    this.checkQuestionTextVisibility(config);
    this.checkUpsellVisibility(config);
    this.getCurrencyPlacement(config);
    this.getAmountSeparator(config);
    this.getLeaderboardToastMessage(config);
    this.getDecorElements(config);
    this.getWinnersMessageDisplayed(config);
    this.getBigPrizeAmount(config);
    this.getCanEditQuestionsAfterEventStart(config);
    this.getLandingPrizeAnimation(config)
  }

  checkMaintenanceMode(settings) {
    const isLock = settings?.find(setting => setting.key === 'maintenance_mode')?.value === 'true';
    this.maintenanceControlService.isMaintenance$.next(isLock);
    this.maintenanceControlService.subscribeMaintenanceCheck();
  }

  checkBannedUserInform(settings) {
    const areBannedUserAllowedPlayGame = settings?.find(setting => setting.key === 'are_banned_users_allowed_play_game')?.value === 'true';
    this.authService.areBannedUserAllowedPlayGame$.next(areBannedUserAllowedPlayGame);
  }

  checkQuestionView(config) {
    this.isMultipleQuestionsOn1Page$ = of(config.find(setting => setting.key === 'multiple_questions_enabled')?.value === 'true');
  }

  checkQuestionTextVisibility(config) {
    this.isHideQuestionText$ = of(config.find(setting => setting.key === 'is_question_text_not_required')?.value === 'true');
  }

  checkUpsellVisibility(config) {
    this.isShowUpsell$ = of(config.find(setting => setting.key === 'show_upsell')?.value === 'true');
  }

  getCurrencyPlacement(config) {
    this.currencyAfterPrize$ = of(config.find(setting => setting.key === 'currency_after_prize_amount')?.value === 'true');
  }

  getAmountSeparator(config) {
    this.amountSeparator$ = of(config.find(setting => setting.key === 'prize_amount_separator')?.value || '.');
  }

  getLeaderboardToastMessage(config) {
    this.isLeaderboardToastMessage$ = of(config.find(setting => setting.key === 'leaderboard_message_enabled')?.value === 'true');
  }

  getWinnersMessageDisplayed(config) {
    this.isWinnersMessageDisplayed$ = of(config.find(setting => setting.key === 'show_winners_animation')?.value === 'true');
  }

  getDecorElements(config) {
    this.decorElements$ = of(config.find(setting => setting.key === 'are_decor_elements_included')?.value).pipe(
      filter(setting => !!setting),
      map((setting) => setting === 'None' ? null : setting)
    );
  }

  getBigPrizeAmount(config) {
    this.bigPrizeAmount$ = of(config.find(setting => setting.key === 'winners_animation_trigger_amount')?.value);
  }

  getCanEditQuestionsAfterEventStart(config) {
    this.canQuestionsAfterEventStart$.next(config.find(setting => setting.key === 'can_edit_question_after_event_start')?.value === 'true');
  }

  getLandingPrizeAnimation(config) {
    this.landingPrizeAnimation$ = of(config.find(setting => setting.key === 'first_prize_animation')?.value === 'true');
  }
}
