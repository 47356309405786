@if (cmsContentService.cmsContent$ | async; as cmsContent ) {
  <div class="team-stats-wrapper">
    <div class="header-wrapper">
      <div class="header">{{ cmsContent.text_content['iframe-v3-team-stats-modal-header'] }}</div>
      <img (click)="closeModal()" class="close" [src]="cmsContent.image_content['iframe-v3-bracket-modal-cross-icon']">
    </div>

    <div class="team-stats-container">
      @for (team of data; track team.id; let i = $index) {
        <div class="single-team-wrapper">
          <div class="team-base-info">
            <div>{{ i + 1 }}</div>
            @if (isImagesPresent) {
              <div class="team-image">
                <img [src]="team.imageUrl">
              </div>
            }
            <div class="team-name">{{ team.name }}</div>
          </div>
          <div class="single-team-stats-wrapper">
            @if (team.stats?.length) {
              @for (stats of team.stats; track i; let i = $index) {
                <div class="single-stat-item" [class]="stats.toLowerCase()">
                  {{ stats[0] }}
                </div>
              }
            }
          </div>
        </div>
      }
    </div>
  </div>
}

