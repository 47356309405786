import { Injectable } from '@angular/core';

import { UserService } from "@services/user.service";

import { environment } from "../../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class SentryLoaderService {

  private isSentryInitialized = false;

  private sentryInstance: any = null;

  private userSaved = false;

  constructor(private userService: UserService) {
  }


  private flushErrorBuffer() {
    if (!this.sentryInstance) return;
    if (!(window as any).__errorBuffer?.length) return;

    (window as any).__errorBuffer.forEach((error) => this.sentryInstance.captureException(error));
    (window as any).__errorBufferr = [];
  }

  public setUpSentry() {
    if (this.isSentryInitialized || (window as any)?.sentryInstance) return;
    //need to test
    // eslint-disable-next-line no-console
    console.log("Initializing Sentry...");

    window.addEventListener("load", () => {
      //need to test
      // eslint-disable-next-line no-console
      console.log('load');
      this.initializeSentry();
    })

    setTimeout(() => {
      if (!this.isSentryInitialized || !(window as any)?.sentryInstance) {
        //need to test
        // eslint-disable-next-line no-console
        console.log('in setTimeout');
        this.initializeSentry();
      }
    }, 1000)
  }

  initializeSentry() {
    import('@sentry/angular').then((Sentry) => {
      //need to test
      // eslint-disable-next-line no-console
      console.log('sentry done');
      (window as any).sentryInstance = Sentry;

      this.sentryInstance = Sentry;

      Sentry.init({
        dsn: environment.sentryDsn,
        ignoreErrors: [
          "User with this nickname already exists",
          "Unauthorized",
          "Forbidden",
          "Cannot read properties of null (reading 'version')",
          "Failed to load CMS content",
          /Http failure response for https:\/\/api\.playhunch\.io\/superbet-api\/validate-ticket\/\d+: 403 OK/
        ],
        environment: environment.name,
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 0.1,
        tracesSampleRate: 0,
      });

      this.setSentryUser();

      Sentry.lazyLoadIntegration("replayIntegration").then(
        (replayIntegration) => {
          //need to test
          // eslint-disable-next-line no-console
          console.log('sentry lazyLoadIntegration');
          Sentry.addIntegration(replayIntegration());
        }
      );

      this.isSentryInitialized = true;
      this.flushErrorBuffer();
    }).catch((err) => {
      console.error("Failed to load Sentry:", err);
    });
  }

  setSentryUser() {
    const user = this.userService.userDetails$.value;
    if ((window as any).sentryInstance && !this.userSaved && user) {
      const { id,  nickname } = user;
      (window as any).sentryInstance.setUser({ id,  nickname });
      this.userSaved = true;
    }
  }
}
