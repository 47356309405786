import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class SupportDvhService {

  constructor() {}

  checkDvhSupport() {
    const element = document.createElement('div');
    element.style.height = '1dvh';
    document.body.appendChild(element);
    const isSupported = element.style.height !== '';
    document.body.removeChild(element);
    return isSupported;
  }

}
