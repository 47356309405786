<div class="lists-container">
  <div class="lists-text">
    {{ text }}
  </div>
  <div class="lists-items-wrapper">
    @for (list of lists; track list.title) {
      <div class="list-item" [ngClass]="{'important': list.isImportant}">
        <img [src]="list.imageUrl">
        <div class="list-content">
          <div class="list-content__title">
            {{ list.title }}
          </div>
          <div class="list-content__description">
            {{ list.description }}
          </div>
        </div>
      </div>
    }
  </div>
</div>
