import { AsyncPipe } from "@angular/common";
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { ModalWindowService } from "@common-services/modal-window.service";
import { BracketTeamModel } from "@models/BracketTeamModel";
import { CmsContentService } from "@services/cms-content.service";

@Component({
  selector: 'hun-team-statistic-modal',
  standalone: true,
  imports: [
    AsyncPipe
  ],
  templateUrl: './team-statistic-modal.component.html',
  styleUrl: './team-statistic-modal.component.scss'
})
export class TeamStatisticModalComponent {

  isImagesPresent: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: BracketTeamModel[],
    public dialogRef: MatDialogRef<TeamStatisticModalComponent>,
    public cmsContentService: CmsContentService,
    private modalWindowService: ModalWindowService,

  ) {
    this.isImagesPresent = this.data.some((team: BracketTeamModel) => team.imageUrl);
  }

  closeModal () {
    this.dialogRef.close();
    this.modalWindowService.isAnyModalOpened.next(false);
  }

}
