import { AsyncPipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { map } from 'rxjs';

import { ModalWindowService } from "@common-services/modal-window.service";
import { PrizeAmountService } from "@common-services/prize-amount.service";
import {
  TwoButtonsConfirmationModalComponent
} from "@components/two-buttons-confirmation-modal/two-buttons-confirmation-modal.component";
import { PrizeTypeEnum } from "@enums/PrizeTypeEnum";
import { ConfirmationModalDetails } from "@models/ConfirmationModalDetails";
import { CmsContentService } from "@services/cms-content.service";
import { SaasSetingsService } from "@services/saas-setings.service";


@Component({
    selector: 'hun-prize-modal',
    templateUrl: './prize-modal.component.html',
    styleUrls: ['./prize-modal.component.scss'],
    standalone: true,
    imports: [TwoButtonsConfirmationModalComponent, AsyncPipe]
})
export class PrizeModalComponent {

  constructor(
    public dialogRef: MatDialogRef<PrizeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationModalDetails,
    public cmsContentService: CmsContentService,
    public prizeAmountService: PrizeAmountService,
    private modalWindowService: ModalWindowService,
    private saasSetingsService: SaasSetingsService,
  ) {
  }

  closeModal () {
    this.dialogRef.close();
    this.modalWindowService.isPrizeModalOpened.next(false);
  }

  getDescription(textContent) {
    return this.saasSetingsService.currencyAfterPrize$.pipe(
      map(currencyAfterPrize => {
        if (this.data.body.isCashPoints) {
          const amount = this.getPrizeAmount(textContent, currencyAfterPrize);
          const amountWrapper = '<span class="amount-wrapper">' + amount +'</span>';
          const description = this.data?.description ? this.data.description?.replace('{maxPrize}', amountWrapper) : '';
          return '<div class="prize-description">' + description +'</div>';
        }
        return '<div class="prize-description">' + textContent['iframe-v3-prize-modal-label-description'] +'</div>';
      })
    )
  }

  getPrizeAmount(textContent, currencyAfterPrize) {
    let prizeAmount;
    if (this.data.body.prizeTotal) {
      prizeAmount = this.data.body.prizeTotal;
    }
    if (this.data.body.showSecondPrizeLine) {
      prizeAmount = textContent['iframe-v3-amount-landing-second-line'];
    }
    prizeAmount = textContent['iframe-v3-amount-landing'];
    return currencyAfterPrize ? `${prizeAmount}${textContent['iframe-v3-currency']}` : `${textContent['iframe-v3-currency']}${prizeAmount}`;
  }

  protected readonly PrizeTypeEnum = PrizeTypeEnum;
}
