export const FRAME_DATA = {
  LIMIT_BOTTOM: 720,
  //This is hardcoded name of group, it using in admin project.
  // If this name need to be changed, it also need to be changed here, in single-question-item component
  GROUP_NAMES: ['group_0', 'group_1'],
  TEAM_NAMES: ['home', 'away'],
  LANDING_PATH: '/frame/landing',
  LEADERBOARD_PATH: '/frame/leaderboard',
  MY_PREDICTIONS_PATH: '/frame/my-predictions',
  MY_PICKS_PATH: '/frame/my-picks',
  RESULTS_PATH: '/frame/results',
  HISTORY_PATH: '/frame/streak/history',
  STREAK_PATH: '/frame/streak/path',
  STREAK_LANDING: '/frame/streak/home',
  STREAK_VIEW_RESULTS: 'streak/view-results',
  LEADERBOARD_PAGE_SIZE: 20,
  BRACKET_LANDING_PATH: '/frame/bracket/landing',
  LOCK_PATH: '/lock',
}
