import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { UriConfig } from "@app/app.config";
import { UserDetailsModel } from "@models/UserDetail";




@Injectable({
  providedIn: 'root'
})
export class ValidTicketService {

  constructor(
    protected http: HttpClient,
    private uriConfig:UriConfig,
  ) { }

  getRestrictedSegmentUser(roundId): Observable<UserDetailsModel> {
    return this.http.get<UserDetailsModel>(this.uriConfig.validTicket + '/' + roundId)
      .pipe(
        catchError((err => throwError(err)))
      );
  }
}
