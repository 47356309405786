import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { Injectable } from "@angular/core";
import { Observable, map, BehaviorSubject } from "rxjs";

import { FRAME_DATA } from "../frame.config";

@Injectable({
  providedIn: 'root'
})
export class IsDesktopService {

  private isDesktopSubject = new BehaviorSubject<boolean>(true);


  isDesktop$ = this.isDesktopSubject.asObservable();

  constructor(
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver.observe([
      `(max-width: ${FRAME_DATA.LIMIT_BOTTOM}px)`
    ]).subscribe((result) => {
      this.isDesktopSubject.next(!result.matches);
    })
  }

}
