@if (cmsContentService.cmsContent$ | async; as cmsContentConfig) {
  <div
    class="timer-wrapper"
    [style.color]="color">
    @if (!(timerService.isOneMinuteLeft$ | async)) {
      <ng-container
    [ngTemplateOutletContext]="{
        days: (timerService.daysTo$ | async),
        daysTitle: cmsContentConfig['text_content']['iframe-v3-my-prediction-timer-day-label'],
        hours: (timerService.hoursTo$ | async),
        hoursTitle: cmsContentConfig['text_content']['iframe-v3-my-prediction-timer-hours-label'],
        minutes: (timerService.minutesTo$ | async),
        minutesTitle: cmsContentConfig['text_content']['iframe-v3-my-prediction-timer-minutes-label'],
        seconds: (timerService.secondsTo$ | async),
        secondsTitle: cmsContentConfig['text_content']['iframe-v3-my-prediction-timer-seconds-label']
      }"
        [ngTemplateOutlet]="timer">
      </ng-container>
    } @else {
      <div class="timer">
        @if (timerService.fullSecondsToAsString$ | async; as fullSecondsToAsString) {
          <div class="timer-item">
            <div class="numbers seconds-numbers">
              <div class="timer-number">{{fullSecondsToAsString[0]}}</div>
              <div class="timer-number">{{fullSecondsToAsString[1]}}</div>
            </div>
            <div class="descriptins">
              <div>{{cmsContentConfig['text_content']['iframe-v3-my-prediction-timer-seconds-label']}}</div>
            </div>
          </div>
        }
      </div>
    }
  </div>
}

<ng-template
  #timer
  let-days="days"
  let-daysTitle="daysTitle"
  let-hours="hours"
  let-hoursTitle="hoursTitle"
  let-minutes="minutes"
  let-minutesTitle="minutesTitle"
  let-seconds="seconds"
  let-secondsTitle="secondsTitle"
  let-cmsContentConfig="cmsContentConfig">
  <div class="timer">
    @if (+days) {
      <div class="timer-item">
        <div class="numbers">
          <div class="timer-number">{{(days)}}</div>
        </div>
        <div class="descriptins">
          <div>{{daysTitle}}</div>
        </div>
      </div>
    }
    <span class="separator">&nbsp;</span>
    @if (hours) {
      <div class="timer-item">
        <div class="numbers">
          <div class="timer-number">{{hours[0]}}</div>
          <div class="timer-number">{{hours[1]}}</div>
        </div>
        <div class="descriptins">
          <div>{{hoursTitle}}</div>
        </div>
      </div>
    }
    <span class="separator">&nbsp;</span>

    @if ((minutes && !+days) || showMinutes) {
      <div class="timer-item">
        <div class="numbers">
          <div class="timer-number">{{minutes[0]}}</div>
          <div class="timer-number">{{minutes[1]}}</div>
        </div>
        <div class="descriptins">
          <div>{{minutesTitle}}</div>
        </div>
      </div>
    }
    <span class="separator">&nbsp;</span>
    @if ((seconds && !+days)) {
      <div class="timer-item">
        <div class="numbers">
          <div class="timer-number">{{ seconds[0] }}</div>
          <div class="timer-number">{{ seconds[1] }}</div>
        </div>
        <div class="descriptins">
          <div>{{ secondsTitle }}</div>
        </div>
      </div>
    }
  </div>
</ng-template>
