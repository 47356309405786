import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { BehaviorSubject, of, switchMap, combineLatest, lastValueFrom, firstValueFrom } from 'rxjs';
import { filter, map, take, tap } from "rxjs/operators";

import { TenantService } from "@common-services/tenant.services";
import { QuestionEnum } from "@enums/QuestionEnum";
import { QuestionDetailsModel } from "@models/QuestionDetail";
import { GameService } from "@services/game.service";
import { QuestionService, ScoreAnswer } from "@services/question.service";
import { SaasSetingsService } from "@services/saas-setings.service";


@Injectable({
  providedIn: 'root'
})
export class MyPredictionsService {

  mobileMenuDisplayed$ = new BehaviorSubject<boolean>(true);

  infoButtonDisplayed$ = new BehaviorSubject<boolean>(true);

  questionsCount$ = this.questionService.questionList$.pipe(
    switchMap((questionList) => of(questionList.length))
  );

  currentQuestionNumber$ = new BehaviorSubject<number>(1);

  currentActiveQuestion$ = new BehaviorSubject<QuestionDetailsModel>(this.questionService.questionList$.value[0]);

  singleQuestionPage$ = new BehaviorSubject<boolean>(false);

  selectedAnswers$ = new BehaviorSubject<number[]>([]);

  isEditingMode$ = new BehaviorSubject<boolean>(false);

  private answeredQuestionOrder = new Set();

  constructor(
    private questionService: QuestionService,
    private gameService: GameService,
    private router: Router,
    private saasSetingsService: SaasSetingsService,
    private tenantService: TenantService,
  ) {
    this.onCurrentRoundChange()
  }

  async onCurrentRoundChange() {
    await lastValueFrom(
      this.gameService.currentRound$.pipe(
        filter(currentRound => !!currentRound),
        take(1),
        tap(currentRound => {
          if (currentRound && this.questionService.roundsQuestionSet$.value) {
            this.questionService.questionList$.next(this.questionService.roundsQuestionSet$.value[currentRound]);
            this.currentActiveQuestion$.next(this.questionService.roundsQuestionSet$.value[currentRound][0]);
          }
        })
      )
    );
  }


  async navigateToPreviousQuestion() {
    await firstValueFrom(
      combineLatest([
        this.currentQuestionNumber$,
        this.questionService.roundsQuestionSet$,
        this.gameService.currentRound$
      ]).pipe(
        take(1),
        tap(([currentQuestionNumber, roundsQuestionSet, currentRound]) => {
          this.currentQuestionNumber$.next((currentQuestionNumber - 1) - 1);
          const questionToNavigate = roundsQuestionSet[currentRound][(currentQuestionNumber - 1) - 1];
          this.currentActiveQuestion$.next(questionToNavigate);
          const { order, id } = questionToNavigate;
          this.questionService.editingQuestion$.next({ order, id });
          this.router.navigate([`frame/my-predictions/${currentRound}/game/${questionToNavigate.id}`]);
        })
      )
    )
  }

  async navigateToNextQuestion() {
    await firstValueFrom(
      combineLatest([
        this.currentQuestionNumber$,
        this.questionService.roundsQuestionSet$,
        this.gameService.currentRound$
      ]).pipe(
        take(1),
        tap(([currentQuestionNumber, roundsQuestionSet, currentRound]) => {
          this.addAnsweredQuestion(currentQuestionNumber);
          this.currentQuestionNumber$.next(currentQuestionNumber + 1);
          const questionToNavigate = roundsQuestionSet[currentRound][currentQuestionNumber];
          this.currentActiveQuestion$.next(questionToNavigate);
          if (this.isEditingMode$.value) {
            this.removeAnsweredQuestion(questionToNavigate.order);
          }
          const { order, id } = questionToNavigate;
          this.questionService.editingQuestion$.next({ order, id });
          this.router.navigate([`frame/my-predictions/${currentRound}/game/${questionToNavigate.id}`]);
        })
      )
    )
  }

  isNavigateNextAvailable() {
    return combineLatest([
      this.selectedAnswers$,
      this.currentActiveQuestion$,
      this.questionService.questionAnswersList$
    ]).pipe(
      map(([selectedAnswers, currentActiveQuestion, questionAnswersList]) => {
        if (!questionAnswersList[currentActiveQuestion.id]) {
          this.router.navigate(['frame']);
          return false;
        }
        if (!selectedAnswers && !selectedAnswers.length) return true;
        if (currentActiveQuestion.type === QuestionEnum.RANGE) {
          return false;
        }
        if (currentActiveQuestion.type === QuestionEnum.SCORE || currentActiveQuestion.type === QuestionEnum.SCORE_PLUS) {
          return (questionAnswersList[currentActiveQuestion.id].answer as ScoreAnswer).home === '-';
        }
        return !selectedAnswers.includes(currentActiveQuestion.id) && !questionAnswersList[currentActiveQuestion.id].answer;
      })
    )
  }

  async editQuestionAnswer(questionId, gameId) {
    const isMultipleQuestionsOn1Page = await firstValueFrom(this.saasSetingsService.isMultipleQuestionsOn1Page$);
    const isStreak = await firstValueFrom(this.tenantService.isStreak$)
    if (isMultipleQuestionsOn1Page || isStreak) {
      const route = isStreak ? `frame/streak/game/${gameId}` : `frame/my-predictions/${gameId}`
      this.router.navigate([route]);
    } else {
      this.router.navigate([`frame/my-predictions/${gameId}/game/${questionId}`]);
    }
  }

  isSubmitAvailable() {
    return combineLatest([
      this.questionService.questionAnswersList$,
      this.currentQuestionNumber$,
      this.currentActiveQuestion$,
      this.gameService.currentRound$,
      this.gameService.roundsSet$,
      this.tenantService.isStreak$
    ]).pipe(
      take(1),
      filter(([questionAnswersList, currentQuestionNumber, currentActiveQuestion, currentRound, roundsSet, isStreak]) => !!currentRound),
      map(([questionAnswersList, currentQuestionNumber, currentActiveQuestion, currentRound, roundsSet, isStreak]) => {
        const questionList = Object.values(questionAnswersList);
        if (currentQuestionNumber === questionList.length) {
          if (currentActiveQuestion.type ===QuestionEnum.RANGE) {
            return true;
          }
        }
        const isRoundPlayed = roundsSet[currentRound].round.hasPlayed;
        if (isRoundPlayed) {
          return questionList.every(questionAnswer => {
            if (questionAnswer.type === QuestionEnum.SCORE || questionAnswer.type === QuestionEnum.SCORE_PLUS) {
              return questionAnswer.answer && questionAnswer.answer.home.toString() && questionAnswer.answer.away.toString() && questionAnswer.answer?.home !== '-' && questionAnswer.answer?.away !== '-';
            } else {
              return questionAnswer.answer;
            }
          })
        } else {
          return questionList.every(questionAnswer => {
            return questionAnswer.type === QuestionEnum.SCORE_PLUS || questionAnswer.type === QuestionEnum.SCORE ?
              questionAnswer.answer?.home !== '-' && questionAnswer.answer?.away !== '-' :
              questionAnswer.userAnswer;
          });
        }
      })
    )
  }

  isHideQuestionText(isSuperbetBrazil, currentActiveQuestion, isHideQuestionText) {
    const questionType = currentActiveQuestion.type || currentActiveQuestion.questionType;
    return isSuperbetBrazil &&
      isHideQuestionText &&
      !!currentActiveQuestion.sportEvent &&
      ( questionType === QuestionEnum.SCORE || questionType === QuestionEnum.SCORE_PLUS);
  }

  selectAnswer(answer, question) {
    if (!this.selectedAnswers$.value.find(item => item === question.id)) {
      this.selectedAnswers$.next([...this.selectedAnswers$.value, question.id]);
    }

    let newAnswer = answer;

    const { id: questionId, type, text, attributes, order } = question;
    this.addAnsweredQuestion(order);

    if (type === QuestionEnum.RANGE) {
      newAnswer = answer === +attributes.maxValue ? `${answer}+` : answer.toString();
    }
    this.questionService.questionAnswersList$.next({
      ...this.questionService.questionAnswersList$.value,
      [questionId]: {
        answer: newAnswer,
        type,
        questionId,
        userAnswer: newAnswer,
        questionText: text,
        order
      }
    });
  }

  addAnsweredQuestion(order) {
    this.answeredQuestionOrder.add(order);
  }

  fillAnsweredQuestion(answereQuestion) {
    answereQuestion.forEach(question => this.answeredQuestionOrder.add(question));
  }

  cleanAnsweredQuestion() {
    this.answeredQuestionOrder.clear();
  }

  isAnsweredIncludeCurrent (order) {
    return this.answeredQuestionOrder.has(order);
  }

  removeAnsweredQuestion(order) {
    this.answeredQuestionOrder.delete(order);
  }

}
