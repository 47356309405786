import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { DecorElementsEnum } from "@enums/DecorElementsEnum";
import { SaasSetingsService } from "@services/saas-setings.service";

@Injectable({
  providedIn: 'root'
})
export class DecorElementsService {

  constructor(public saasSettingsService: SaasSetingsService) { }

  isLightsAvailable(): Observable<boolean> {
    return this.saasSettingsService.decorElements$.pipe(
      map(setting => setting === DecorElementsEnum.ONLY_LIGHTS || setting === DecorElementsEnum.BOTH)
    )
  }

  isBackgroundAvailable(): Observable<boolean> {
    return this.saasSettingsService.decorElements$.pipe(
      map(setting => setting === DecorElementsEnum.BOTH)
    )
  }

  getDecorElementsClass(): Observable<string> {
    return this.saasSettingsService.decorElements$.pipe(
      map((setting: DecorElementsEnum) => {
        switch (setting) {
          case DecorElementsEnum.ONLY_LIGHTS:
            return 'container__only-lights';
          case DecorElementsEnum.ONLY_BACKGROUND:
            return 'container__only-background';
          case DecorElementsEnum.BOTH:
            return 'container__only-background container__only-lights';
          default:
            return '';
        }
      })
    )
  }

}
