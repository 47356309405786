import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'hun-info-modal-lists',
    templateUrl: './info-modal-lists.component.html',
    styleUrls: ['./info-modal-lists.component.scss'],
    standalone: true,
  imports: [NgClass]
})
export class InfoModalListsComponent {

  private _lists: { title: string; description: string; imageUrl: string, isImportant: boolean }[] = [];

  private _text: string = '';

  @Input()
  set lists(value: { title: string; description: string; imageUrl: string, isImportant: boolean }[]) {
    this._lists = value;
  }

  get lists(): { title: string; description: string; imageUrl: string, isImportant: boolean }[] {
    return this._lists;
  }

  @Input()
  set text(value: string) {
    this._text = value;
  }

  get text(): string {
    return this._text;
  }

  constructor() {}
}
