import { Injectable } from "@angular/core";
import { addMinutes, addSeconds, differenceInSeconds, intervalToDuration } from "date-fns";
import { BehaviorSubject, Observable, combineLatest, interval } from "rxjs";
import { filter, map, startWith } from "rxjs/operators";

import { TenantService } from "@common-services/tenant.services";
import { BracketGameService } from "@services/bracket-game.service";
import { GameService } from "@services/game.service";



@Injectable()
export class TimerService {

  daysTo$: Observable<string> = new Observable();
  daysToWithZero$: Observable<string> = new Observable();
  hoursTo$: Observable<string> = new Observable();
  hoursToWithZero$: Observable<string> = new Observable();
  minutesTo$: Observable<string> = new Observable();
  minutesToWithZero$: Observable<string> = new Observable();
  fullSecondsTo$: Observable<number> = new Observable();
  fullSecondsToAsString$: Observable<string> = new Observable();
  isOneMinuteLeft$: Observable<boolean> = new Observable();
  secondsTo$: Observable<string> = new Observable();

  isRoundReloaded = false;

  isCloseTime: boolean = false;

  private date$: BehaviorSubject<string> = new BehaviorSubject(undefined);

  set date(date: string) {
    this.date$.next(date)
  }

  private addZeroIfNoSecondNumber(number: string) {
    return number.length < 2 ? `0${number}` : number;
  }

  constructor(
    private gameService: GameService,
    private tenantService: TenantService,
    private bracketGameService: BracketGameService
  ) {
    const gameInterval = combineLatest([
      interval(1000).pipe(
        startWith(0)
      ),
      this.date$
    ]).pipe(
      map(([_, date]) => date)
    );
    // timesTo$
    const baseData$ = combineLatest([gameInterval, this.tenantService.isStreak$, this.tenantService.isBracket$]).pipe(
      filter(([date, _]) => !!date),
      map(([date, isStreak, isBracket]) => {
        //  PREDICTION: We may have a time difference between the front and backend.
        // There may be a situation where, according to the time on the front end,
        // the round has ended, but on the back end the round has not been updated.
        // And as a result, the update request will return the open round.
        // Therefore, for the front, the round completion time has been increased by one minute.
        // STREAK: Don't add 1 minute for close time, to prevent submit after close
        //         1s to be sure that round will be closed
        const inputDate = isStreak && this.isCloseTime
          ? addSeconds(new Date(date), 1)
          : addMinutes(new Date(date), 1);

        const currentDate = new Date();

        const diffSeconds = differenceInSeconds(inputDate, currentDate);


        if (diffSeconds <= 0 && !this.isRoundReloaded) {
          this.isRoundReloaded = true;
          if (isBracket) {
            this.bracketGameService.reloadRound();
          } else {
            this.gameService.reloadRound();
          }
        }

        return {
          fullSeconds: Math.max(0, diffSeconds),
          duration: intervalToDuration({
            start: currentDate,
            end: diffSeconds > 0 ? inputDate : currentDate
          })
        };
      })
    );

    const fullTimeData$ = baseData$.pipe(
      map(({ fullSeconds }) => {
        const days = Math.floor(fullSeconds / 86400); // 60 * 60 * 24
        const hours = Math.floor((fullSeconds % 86400) / 3600);
        const minutes = Math.floor((fullSeconds % 3600) / 60);
        const seconds = fullSeconds % 60;

        return { days, hours, minutes, seconds };
      })
    );

    this.fullSecondsTo$ = baseData$.pipe(
      map(times => times.fullSeconds)
    );

    this.fullSecondsToAsString$ = this.fullSecondsTo$.pipe(
      map(seconds => seconds.toString())
    );

    this.isOneMinuteLeft$ = this.fullSecondsTo$.pipe(
      map(seconds => seconds < 60)
    );

    this.daysTo$ = fullTimeData$.pipe(
      map(data => data.days.toString())
    );

    this.hoursTo$ = fullTimeData$.pipe(
      map(data => data.hours.toString())
    );

    this.minutesTo$ = fullTimeData$.pipe(
      map(data => data.minutes.toString())
    );

    this.secondsTo$ = fullTimeData$.pipe(
      map(data => data.seconds.toString())
    );


    this.daysToWithZero$ = this.daysTo$.pipe(
      map(days => this.addZeroIfNoSecondNumber(days))
    );

    this.hoursToWithZero$ = this.hoursTo$.pipe(
      map(hours => this.addZeroIfNoSecondNumber(hours))
    );

    this.minutesToWithZero$ = this.minutesTo$.pipe(
      map(minutes => this.addZeroIfNoSecondNumber(minutes))
    );

  }
}
