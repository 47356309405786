import { Injectable } from '@angular/core';

import { AnalyticsEventEnum } from "@enums/AnalyticsEventEnum";

import { OutsideMessageServices } from "./outside-messages.services";



@Injectable({
	providedIn: 'root'
})
export class GAnalyticsService {

	constructor(
		private outsideMessageServices: OutsideMessageServices
	) {
	}

	toPlay(parameters) {
		this.processEventPlatformSensitive({ type: AnalyticsEventEnum.PLAY, parameters });
	}

	submitGame(parameters) {
		this.processEventPlatformSensitive({ type: AnalyticsEventEnum.SUBMIT_GAME, parameters });
	}

	selectAnswer(parameters) {
		this.processEventPlatformSensitive({ type: AnalyticsEventEnum.ANSWER_CLICK, parameters });
	}

	bannerClick(parameters) {
		this.processEventPlatformSensitive({ type: AnalyticsEventEnum.BANNER_CLICK, parameters });
	}

  bannerImpression(parameters) {
    this.processEventPlatformSensitive({ type: AnalyticsEventEnum.BANNER_IMPRESSION, parameters });
  }

	frameLoaded() {
		this.processEventPlatformSensitive({ type: AnalyticsEventEnum.FRAME_LOADED });
	}

	processEventPlatformSensitive(parametersBody) {
		if ((window as any)?.android) {
			this.outsideMessageServices.sendMessageToAndroidParent(JSON.stringify(parametersBody));
		} else if ((window as any)?.webkit?.messageHandlers?.gameHandler) {
			this.outsideMessageServices.sendMessageToIOSParent(JSON.stringify(parametersBody));
		} else {
      if (parametersBody.type) {
        parametersBody.event = parametersBody.type;
        delete parametersBody.type;
      }
			this.sendEvent(parametersBody);
		}
	}

	sendEvent(data) {
		if (window && (window as any).dataLayer) {
			(window as any).dataLayer.push(data);
		}
	}
}
