import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import {
  BehaviorSubject,
  delay,
  of,
  Subject,
  tap,
} from "rxjs";
import { filter, map, takeUntil } from "rxjs/operators";

import { UriConfig } from "@app/app.config";
import { LocalStorageService } from "@services/local-storage.service";

import { AuthService } from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class MaintenanceControlService {

  isMaintenance$ = new BehaviorSubject(false);

  private unsubscribe$ = new Subject<void>();
  private controller = new AbortController()

  private retryCount = 0;
  private readonly MAX_RETRIES = 10;
  private readonly BASE_DELAY = 5000;

  constructor(
    private uriConfig: UriConfig,
    private router: Router,
    private authService: AuthService,
    private localStorageService: LocalStorageService,
    protected http: HttpClient,
  ) {
  }

  subscribeMaintenanceCheck(): void  {
    if (!this.controller.signal.aborted) {
      this.controller.abort();
    }
    this.controller = new AbortController();

    if (this.retryCount >= this.MAX_RETRIES) {
      console.warn('Max retry attempts reached. Stopping SSE connection attempts.');
      return;
    }

    const token = this.localStorageService.getToken();
    const url = `${this.uriConfig.sse}/b2c`;
    const { signal } = this.controller

    fetchEventSource(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      },
      openWhenHidden: true,
      signal,
      onopen: () => {
        this.retryCount = 0;
        return null
      },
      onmessage: (event) => {
        try {
          if(event.data) {
            const data = JSON.parse(event.data);
            this.isMaintenance$.next(data.isMaintenanceModeEnabled);
          }
        } catch (error) {
          console.error('Error parsing maintenance status update:', error);
        }
      },
      onerror: (error) => {
        this.reconnect();
      },
    }).catch(error => {
      console.error('Error establishing SSE connection:', error);
    });
  }


  private reconnect(): void {
    if (this.retryCount >= this.MAX_RETRIES) {
      console.warn('Stopping reconnection attempts after reaching max retries.');
      return;
    }

    const retryDelay = this.BASE_DELAY * Math.pow(2, this.retryCount);

    console.warn(`Reconnecting in ${retryDelay / 1000} seconds... (Attempt ${this.retryCount}/${this.MAX_RETRIES})`);

    of(true).pipe(
      delay(retryDelay),
      tap(() => this.subscribeMaintenanceCheck()),
      takeUntil(this.unsubscribe$)
    ).subscribe();
  }


  closeConnection() {
    this.controller.abort();
  }

  destroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  maintenanceControl() {
    this.isMaintenance$.pipe(
      map(isMaintenance => {
        if (isMaintenance) {
          this.router.navigate([`lock`]);
        }
        return isMaintenance;
      }),
      filter(isMaintenance => !isMaintenance),
      map(() => this.authService.initUser()),
      tap(() => {
        if (window.location.href.includes('lock')) {
          this.router.navigate([`frame`]);
        }
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe();
  }
}
