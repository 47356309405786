import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { isBefore } from "date-fns";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';

import { UriConfig } from "@app/app.config";
import { QuestionEnum } from "@enums/QuestionEnum";
import { RoundClassEnum } from "@enums/RoundClassEnum";
import { QuestionDetailsModel } from "@models/QuestionDetail";
import { AnswerService } from "@services/answer.service";
import { CmsContentService } from "@services/cms-content.service";
import { SaasSetingsService } from "@services/saas-setings.service";
import { SnackBarService } from "@services/snack-bar.service";
import { QuestionItemDetailsModel } from 'src/app/app-common/models/QuestionItemDetailsModel';




export class QuestionAnswerModel {
  [key: number]: {
    answer: Answer,
    type: QuestionEnum,
    questionId: number,
    userAnswer?: string,
    questionText?: string
    roundId?: number
    order: number
  };
}

export type Answer = ScoreAnswer | number | string;

export type ScoreAnswer = {
  home: number | null | string,
  away: number | null | string
}

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  public questionAnswersList$: BehaviorSubject< QuestionAnswerModel > = new BehaviorSubject(new QuestionAnswerModel());

  isAnswerWaitForSubmit$: BehaviorSubject< boolean > = new BehaviorSubject(false);

  questionList$ = new BehaviorSubject<QuestionDetailsModel[]>([]);

  isAllQuestionAnswersSet$: Observable<boolean>;

  editingQuestion$ = new BehaviorSubject<{ order: number, id: number }>(null);

  previewQuestionId$ = new BehaviorSubject<string[]>([]);

  roundsQuestionSet$ = new BehaviorSubject<QuestionItemDetailsModel>(undefined)

  constructor(
    protected http: HttpClient,
    private uriConfig: UriConfig,
    private answerService: AnswerService,
    private snackBarService: SnackBarService,
    private cmsContentService: CmsContentService,
    private saasSettingsService: SaasSetingsService
  ) {
    this.isAllQuestionAnswersSet$ = this.questionAnswersList$
      .pipe(
        map(questionAnswers => {
          return Object.values(questionAnswers)
              .every(answer => {
                return this.isScoreQuestion(answer.type) ?
                (parseInt(answer.answer.home) >= 0) && (parseInt(answer.answer.away) >= 0):
                answer.answer || answer.answer === 0;
              })
          }
        )
      );
  }

  getQuestionListByRoundId(roundId, previewQuestionId?, isStreak = false): Observable<QuestionDetailsModel[]> {
    let params = new HttpParams();
    if (previewQuestionId && previewQuestionId.length) {
      params = params.set('ids', previewQuestionId.join(','))
    }
    if (isStreak) {
      return this.http.get<QuestionDetailsModel>(
        this.uriConfig.questions + '/b2c/streak/' + roundId,
        { params }
      ).pipe(map(res => [res]));
    } else {
      return this.http.get<QuestionDetailsModel[]>(
        this.uriConfig.questions + '/b2c/' + roundId,
        { params }
      );
    }
  }

  setAnswersFromQuestions(questionArray, answers = null, isNullScoreAnswers = false, roundId) {
    const userAnswers: { [key: number]: Answer } = {}
    if (questionArray && questionArray.length && answers) {
      answers.history.forEach(item => {
        switch (item.questionType) {
          case QuestionEnum.SCORE:
          case QuestionEnum.SCORE_PLUS:
            if (item.userAnswer) {
              const values = item.userAnswer.split(':');
              userAnswers[item.questionId] = {
                home: item.questionType === QuestionEnum.SCORE_PLUS ? values[0] : +values[0],
                away: item.questionType === QuestionEnum.SCORE_PLUS ? values[1] : +values[1]
              }
            } else {
              userAnswers[item.questionId] = null;
            }
            break;
          case QuestionEnum.RANGE:
            userAnswers[item.questionId] = item.userAnswer;
            break;
          default:
            userAnswers[item.questionId] = item.answerId;
        }
      })
    }

    if (questionArray && questionArray.length) {
      const questionAnswers: QuestionAnswerModel = {};
      questionArray.forEach(question => {
        const { id: questionId, type, text: questionText, order } = question;
        questionAnswers[questionId] = {
          answer: answers && answers.history.find(item => item.questionId === questionId) ? userAnswers[questionId] : this.getDefaultAnswerByQuestionType(question, isNullScoreAnswers),
          type,
          questionId,
          questionText,
          roundId,
          order,
          userAnswer: answers ? answers.history.find(historyItem => historyItem.questionId === questionId)?.userAnswer : this.getDefaultAnswerByQuestionType(question, isNullScoreAnswers)
        }
      });
      this.questionAnswersList$.next(questionAnswers);
    }
  }

  getDefaultAnswerByQuestionType (question, isNullScoreAnswers) {
    const { type, attributes } = question
    switch (type) {
      case QuestionEnum.RANGE:
        return attributes.minValue;
      case QuestionEnum.SCORE:
      case QuestionEnum.SCORE_PLUS:
        return { away: '-', home: '-' };
      default:
        return  null;
    }
  }

  submitAllAnswers(roundId) {
    return this.questionAnswersList$.pipe(
      take(1),
      filter(questionAnswersList => {
        const response =   Object.values(questionAnswersList).every(answer => {
          switch (answer.type) {
            case QuestionEnum.RANGE:
              return !!(answer.answer || answer.answer === 0);
            case QuestionEnum.SCORE:
            case QuestionEnum.SCORE_PLUS:
              return !!(answer.answer.home === 0 || answer.answer.home) && !!(answer.answer.away === 0 || answer.answer.away);
            default:
              return !!answer.answer;
          }
        })
        if (!response) {
          this.snackBarService.showSnackBar(this.cmsContentService.cmsContent$.value['text_content']['iframe-v2-select-answer-error'])
        }
        return response;
      }),
      switchMap(questionAnswersList => {
        const answers = this.prepareAnswersBody(questionAnswersList);

        return this.answerService.submitAnswersScope({ roundId, answers })
      }),
      catchError(err => {
        return throwError(err)
      })
    )
  }


  prepareAnswersBody(questionAnswersList: QuestionAnswerModel) {
    const answers = [];
    Object.values(questionAnswersList).forEach(question => {
      const { questionId, answer } = question;
      switch (question.type) {
        case QuestionEnum.RANGE:
          answers.push({ value: answer.toString(), questionId });
          break;
        case QuestionEnum.SCORE:
        case QuestionEnum.SCORE_PLUS:
          const value = `${answer.home}:${answer.away}`
          answers.push({ value, questionId });
          break;
        default:
          answers.push({ answerId: answer, questionId });
          break;
      }
    });
    return answers;
  }

  isScoreQuestion(questionType: QuestionEnum) {
    return questionType === QuestionEnum.SCORE_PLUS || questionType === QuestionEnum.SCORE;
  }

  canEditQuestions(startDate: string, roundClass: RoundClassEnum) {
    const canEdit = this.saasSettingsService.canQuestionsAfterEventStart$.value
    const isSportEventStarted = isBefore(new Date(startDate), new Date())
    return roundClass === RoundClassEnum.Multi ? canEdit || !canEdit && !isSportEventStarted : true
  }

  getReplacedAnswerText(answer, config) {
    if (!answer) return answer;

    const answerEnding = answer.toLowerCase().replace('_', '-');
    const autoResultedPrefix = 'iframe-v3-autoresulting-answer-';
    return config.text_content[autoResultedPrefix + answerEnding] || answer;
  }
}
