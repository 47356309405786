import { Location } from "@angular/common";
import { Injectable } from '@angular/core';
import { combineLatest, switchMap } from "rxjs";
import { filter, tap } from "rxjs/operators";


import { ModalWindowService } from "@common-services/modal-window.service";
import { AuthService } from "@services/auth.service";
import { UserService } from "@services/user.service";

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private modalWindowService: ModalWindowService,
    private location: Location,
  ) {
  }
  private internalNavigation = false;

  setInternalNavigation(value: boolean): void {
    this.internalNavigation = value;
  }

  getInternalNavigation(): boolean {
    return this.internalNavigation;
  }

  resetNavigation(): void {
    this.internalNavigation = false;
  }

  handleRestrictedUsers() {
    return combineLatest([
      this.userService.isRestrictedUser$,
      this.authService.areBannedUserAllowedPlayGame$,
    ]).pipe(
      filter(([isRestrictedUser, isBannedUsersPopupEnabled]) => isRestrictedUser && !isBannedUsersPopupEnabled),
      switchMap(() => this.modalWindowService.bannedUserModal()),
      tap(() => {
        if (window.location?.ancestorOrigins?.length) {
          window.location.href = window.location.ancestorOrigins[0];
        } else {
          this.location.back();
        }
      })
    )
  }
}
