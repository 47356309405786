import { NgClass, AsyncPipe } from '@angular/common';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { isString } from "lodash";
import { BehaviorSubject, map, Observable, Subject, switchMap, combineLatest } from "rxjs";
import { filter, takeUntil, tap } from "rxjs/operators";

import { InfoPopupService } from "@common-services/info-popup.service";
import { IsDesktopService } from "@common-services/is-desktop.services";
import { TenantService } from "@common-services/tenant.services";
import { InfoModalFilterComponent } from "@components/info-modal/info-modal-filter/info-modal-filter.component";
import { InfoModalListsComponent } from "@components/info-modal/info-modal-lists/info-modal-lists.component";
import { InfoModalSliderComponent } from "@components/info-modal/info-modal-slider/info-modal-slider.component";
import { ItemsExpansionPannelComponent } from "@components/items-expansion-pannel/items-expansion-pannel.component";
import { MainModalComponent } from "@components/main-modal/main-modal.component";
import { ModalHeaderComponent } from "@components/modal-header/modal-header.component";
import { InfoModalTabsEnum } from "@enums/InfoModalTabsEnum";
import { InfoModalTabModel } from "@models/InfoModalTabModel";
import { InfoModalTabsItemDetail } from "@models/InfoModalTabsItemDetail";
import { CmsContentService } from "@services/cms-content.service";
import { LanguageService } from "@services/language.service";


@Component({
    selector: 'hun-info-modal',
    templateUrl: './info-modal.component.html',
    styleUrls: ['./info-modal.component.scss'],
    standalone: true,
  imports: [MainModalComponent, NgClass, ModalHeaderComponent, InfoModalFilterComponent, ItemsExpansionPannelComponent, InfoModalSliderComponent, AsyncPipe, InfoModalListsComponent]
})
export class InfoModalComponent implements AfterViewInit, OnInit, OnDestroy  {

  menuFilterOptions$ = new BehaviorSubject<InfoModalTabsItemDetail[]>([]);

  selectedOption$ = new BehaviorSubject<InfoModalTabsItemDetail>(null);

  showLeftGradient$ = new BehaviorSubject<boolean>(false);

  showRightGradient$ = new BehaviorSubject<boolean>(false);

  infoModalTabsData$: Observable<InfoModalTabModel[]>;

  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    @Inject( MAT_BOTTOM_SHEET_DATA) public data: InfoModalTabsEnum,
    public cmsContentService: CmsContentService,
    public isDesktopService: IsDesktopService,
    public languageService: LanguageService,
    private infoPopupService: InfoPopupService,
    public tenantService: TenantService
  ) {}

  ngOnInit() {
    this.infoModalTabsData$ = this.infoPopupService.getInfoModalTabs().pipe(
      switchMap(infoModalTabs => {
        return this.infoPopupService.getInfoPopupData().pipe(map(infoModalData => {
          infoModalTabs = infoModalTabs.map(res => infoModalData && isString(res.content) && res.content.match(/{([^{}]*)}/g) ? {
            ...res,
            content: this.infoPopupService.generateInfoPopupContent(res.content, infoModalData)
          } : res)
          return infoModalTabs
        }));
      }),
      tap((data) => {
        let filterOptions = data.map((tab) => {
          let content = null;

          if (this.isLocaleInitialized() && this.isJSONString(tab.content)) {
            content = JSON.parse(tab.content);
          } else {
            content = tab.content
          }

          const isFAQ = !!content.items;

          const isSlider = !!content.slides;

          const isLists = !!content.lists;

          const { name, title, order, text, id } = tab;

          return {
            label: name,
            value: content,
            expandedLabel: title,
            isSelected: order === 1,
            isFAQ: isFAQ,
            isSlider: isSlider,
            isLists: isLists,
            text,
            id
          };
        })

        this.selectedOption$.next(filterOptions.find(item => item.isSelected));
        this.menuFilterOptions$.next(filterOptions);
      })
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  isLocaleInitialized() {
    return this.languageService.currentLocale$.value && this.languageService.currentLocale$.value !== 'default'
  }

  isJSONString(str: string): boolean {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }

  hasScrollLeft(element) {
    return element?.scrollLeft > 0;
  }

  hasScrollRight(element) {
    return element?.scrollLeft + 1 < (element?.scrollWidth - element?.clientWidth);
  }

  ngAfterViewInit() {
    combineLatest([
      this.infoModalTabsData$,
      this.selectedOption$.pipe(filter(value => !!value))
    ]).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(([data]) => {
      if (data) {
        setTimeout(() => {
          const target = document.querySelectorAll('.mat-mdc-tab-link-container')[1];
          this.showRightGradient$.next(target?.scrollWidth > target?.clientWidth);
          window?.addEventListener('resize', () => {
            this.showLeftGradient$.next(this.hasScrollLeft(target));
            this.showRightGradient$.next((this.hasScrollRight(target)));
          });
          target?.addEventListener('scroll', (event) => {
            this.showLeftGradient$.next(this.hasScrollLeft(event.target));
            this.showRightGradient$.next((this.hasScrollRight(event.target)));
          })
        }, 0)
      }
    });
  }

  onSelectFilterOption(selectedFilter, filterOptions) {
    const updatedFilterOption = filterOptions.map(option => {
      const newOption = { ...option };
      newOption.isSelected = newOption.label === selectedFilter;
      return newOption;
    });
    this.selectedOption$.next(updatedFilterOption.find(option => option.isSelected));
    this.menuFilterOptions$.next(updatedFilterOption);
  }

}
