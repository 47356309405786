import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { isAfter } from "date-fns";
import { isArray, isEqual, isNull } from "lodash";
import { BehaviorSubject, combineLatest, Observable, of, Subject } from "rxjs";
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  tap
} from "rxjs/operators";

import { UriConfig } from "@app/app.config";
import { ModalWindowService } from "@common-services/modal-window.service";
import { StreakService } from "@common-services/streak.service";
import { TenantService } from "@common-services/tenant.services";
import { UserLeaderboardService } from "@common-services/user-leaderboard.service";
import { GameStatusEnum } from "@enums/GameStatusEnum";
import { HistoricalAnswerStatusEnum } from "@enums/HistoricalAnswerStatusEnum";
import { QuestionEnum } from "@enums/QuestionEnum";
import { RoundClassEnum } from "@enums/RoundClassEnum";
import { GameDetailModel } from "@models/Game";
import { QuestionDetailsModel, SavedQuestionModel, ScoreQuestionAnswer } from "@models/QuestionDetail";
import { RoundSetDetailsModel } from "@models/RoundItemDetailsModel";
import { StreakDetailModel } from "@models/StreakDetailModel";
import { SubmittedAnswersDetailsModel } from "@models/SubmittedAnswersDetail";
import { QuestionItemDetailsModel } from 'src/app/app-common/models/QuestionItemDetailsModel';

import { CmsContentService } from "./cms-content.service";
import { LanguageService } from './language.service';
import { LocalStorageService } from "./local-storage.service";
import { QuestionService } from "./question.service";
import { UserService } from './user.service';


@Injectable({
  providedIn: 'root'
})
export class GameService {

  round$ = new BehaviorSubject<GameDetailModel | StreakDetailModel>(undefined);

  roundsSet$ = new BehaviorSubject<RoundSetDetailsModel | StreakDetailModel>(undefined);

  roundsIds$ = new BehaviorSubject<number[]>([]);

  currentRound$ = new BehaviorSubject<number>(undefined);

  isSubmitActionHappened$ = new BehaviorSubject<boolean>(false);

  roundHistory$ = new BehaviorSubject<SubmittedAnswersDetailsModel>(null);

  resetGameHistory$ = new BehaviorSubject(false);

  resetGameHistory_v3$ = new BehaviorSubject(false);

  answerHistory$: Observable<SubmittedAnswersDetailsModel>;

  answerHistory_v3$: Observable<SubmittedAnswersDetailsModel>;

  areDefaultRoundsLoaded$ = new BehaviorSubject(false);

  showUpsellOnLanding$ = new BehaviorSubject(false);

  isSingleEventRound$= this.round$.pipe(
    map(round => {
      if (!(round instanceof StreakDetailModel)) {
        return round.class === RoundClassEnum.Single
      } else {
        return true
      }
    })
  );

  defaultRoundId$= this.round$.pipe(
    map(round => round.id)
  );

  isDataProcessingDisallow$= this.round$.pipe(
    map(round => round.status === GameStatusEnum.COMPLETED || round.status === GameStatusEnum.PENDING || round.status === GameStatusEnum.RESULT_PROCESSING)
  );

  isPreviewById$ =  new BehaviorSubject(false);

  isPreviewGame$ =  new BehaviorSubject(false);

  isSubmitFromPreview$ =  new BehaviorSubject(false);

  uniqueKey$: Observable<string>;

  previewLocales$: Observable<{ value: string, label: string }[]>;

  isSubmitActionHappen$ = new BehaviorSubject(false);

  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    protected http: HttpClient,
    private uriConfig: UriConfig,
    private questionService: QuestionService,
    private localStorageService: LocalStorageService,
    private tenantService: TenantService,
    private languageService: LanguageService,
    private userService: UserService,
    private cmsContentService: CmsContentService,
    private streakService: StreakService,
    private userLeaderboardService: UserLeaderboardService,
    private modalWindowService: ModalWindowService
  ) {
    this.roundHistory();
    this.roundHistory_v3();
  }


  reloadGameHistory_v3(state) {
    this.resetGameHistory_v3$.next(state);
  }

  getRoundHistory(roundId, streakRoundId?: number): Observable<SubmittedAnswersDetailsModel> {
    let params = new HttpParams();
    if (streakRoundId) {
      params = params.append('streakRoundId', streakRoundId);
    }
    return this.http.get<SubmittedAnswersDetailsModel>(
      this.uriConfig.rounds + '/' + roundId + '/history', { params });
  }

  getGamePreview(uniqueKey, accessToken) {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${accessToken}`);
    const options = { headers };
    return this.http.get<GameDetailModel>(this.uriConfig.rounds + '/preview/' + uniqueKey, options)
  }

  getPreviewGame(uniqueKey, accessToken) {
    return combineLatest([
      this.getGamePreview(uniqueKey, accessToken),
      this.tenantService.isSuperbetBrazil$,
      this.isSubmitFromPreview$
    ]).pipe(
      switchMap(([round, isSuperbetBrazil, isSubmitFromPreview]) => {
        this.round$.next(round);
        this.questionService.questionList$.next(round.questions);
        if (!isSubmitFromPreview) {
          this.questionService.setAnswersFromQuestions(round.questions, null, isSuperbetBrazil, this.currentRound$.value);
        }
        return of(round);
      })
    )
  }

  prepareRoundsSet(cmsContent, roundList: GameDetailModel[]) {
    const roundSet = new RoundSetDetailsModel();
    roundList.forEach((round, index) => {
      const gameButtonText =  this.getButtonText(round, cmsContent);
      const gameButtonColor = this.getButtonColor(round, cmsContent);
      const gameButtonTextColor = this.getButtonTextColor(round, cmsContent);
      const gameButtonBorderColor = this.getButtonBorderColor(round, cmsContent);
      const prizeLabel = round.prizeLabelText;
      const labelTextContent = cmsContent['text_content'][`iframe-v3-landing-round-label-${index + 1}`];
      const labelIconContent = `iframe-v3-landing-round-icon-${index + 1}`;
      const bonusText = cmsContent['text_content'][`iframe-v3-landing-multi-round-bonus-text-${index + 1}`];
      const prizeText = round.prizeText;

      roundSet[round.id] = {
        round,
        isSingleEventRound: round.class === RoundClassEnum.Single,
        isDataProcessingDisallow: round.status === GameStatusEnum.COMPLETED || round.status === GameStatusEnum.PENDING || round.status === GameStatusEnum.RESULT_PROCESSING,
        isHistoryDisallow: round.status === GameStatusEnum.COMPLETED || round.status === GameStatusEnum.PENDING,
        gameButtonText,
        gameButtonColor,
        gameButtonTextColor,
        gameButtonBorderColor,
        gameStatus: round.status,
        prizeLabel,
        labelTextContent,
        labelIconContent,
        bonusText,
        prizeText
      }
    })
    return roundSet;
  }

  prepareRoundForStreak(cmsContent, round: StreakDetailModel) {
    const roundSet = {}

    round['gameButtonText'] = this.getStreakButtonText(round, cmsContent);
    round['gameButtonColor'] = this.getStreakButtonColor(round, cmsContent);
    round['gameButtonTextColor'] = this.getButtonTextStreakColor(round, cmsContent);
    round['isStreakOpened'] = round.status !== GameStatusEnum.PENDING;
    round['round'] = round.currentRoundData;
    round['nextRoundOpensAt'] = round.nextRoundOpensAt && isAfter(new Date(round.nextRoundOpensAt), new Date()) ?
      round.nextRoundOpensAt
      : isAfter(new Date(round.closeDate), new Date()) ? round.closeDate : null;

    if (round.currentRoundData)
      roundSet[round.currentRoundData.id] = round
    return roundSet;
  }


  roundHistory(){
    this.answerHistory$ = this.resetGameHistory$
      .pipe(
        filter(value => value),
        switchMap(() => {
          return combineLatest([
            this.defaultRoundId$,
            this.isPreviewGame$
          ])
        }),
        switchMap(([defaultRoundId, isPreviewGame]) => {
          return isPreviewGame ?
            combineLatest([this.getHistoryForPreview(), of(isPreviewGame)]) :
            combineLatest([this.getRoundHistory(defaultRoundId), of(isPreviewGame)]);
        }),
        switchMap(([roundHistory,isPreviewGame]) => {
          return isPreviewGame ?
            of(roundHistory) :
            this.questionService.questionList$
              .pipe(
                switchMap((questionList) => {
                  this.questionService.setAnswersFromQuestions(questionList, roundHistory, false, this.currentRound$.value);
                  this.roundHistory$.next(roundHistory);
                  return of(roundHistory);
                })
              )
        }),
      )
  }

  roundHistory_v3(){
    this.answerHistory_v3$ = this.resetGameHistory_v3$
      .pipe(
        filter(value => value),
        switchMap(() => {
          return combineLatest([
            this.round$,
            this.currentRound$,
            this.isPreviewGame$,
            this.tenantService.isStreak$
          ]).pipe(take(1))
        }),
        switchMap(([currentRound, currentRoundId, isPreviewGame, isStreak]) => {
          const history$ = isStreak ? this.getRoundHistory(currentRound.id, (currentRound as StreakDetailModel).currentRoundData.id) : this.getRoundHistory(currentRoundId);
          return isPreviewGame ?
            combineLatest([this.getHistoryForPreview(), of(isPreviewGame), this.cmsContentService.cmsContent$]) :
            combineLatest([history$, of(isPreviewGame), this.cmsContentService.cmsContent$]).pipe(takeUntil(this.unsubscribe$));
        }),
        switchMap(([roundHistory,isPreviewGame, cmsContent]) => {
          return isPreviewGame ?
            of(roundHistory) :
            this.questionService.questionList$
              .pipe(
                switchMap((questionList) => {
                  this.questionService.setAnswersFromQuestions(questionList, roundHistory, false, this.currentRound$.value);
                  roundHistory.history.forEach(historyItem => {
                    const { correctAnswer, userAnswer } = historyItem
                    if (correctAnswer) {
                      historyItem.correctAnswer = this.questionService.getReplacedAnswerText( correctAnswer, cmsContent);
                    }
                    historyItem.userAnswer = this.questionService.getReplacedAnswerText(userAnswer, cmsContent);
                  })
                  this.roundHistory$.next(roundHistory);
                  this.reloadGameHistory_v3(false);
                  return of(roundHistory);
                }),
                takeUntil(this.unsubscribe$),
              )
        }),
        distinctUntilChanged(isEqual),
        shareReplay(1),
        takeUntil(this.unsubscribe$),
      )
  }

  getDefaultRounds(roundId?, uniqueKey?) {
    return this.tenantService.isStreak$
      .pipe(
        switchMap((isStreak: boolean) => {
          let params = new HttpParams();
          if (roundId && uniqueKey) {
            params = params.append('id', roundId);
            params = params.append('key', uniqueKey);
          }
          // TODO it should changed, temporal solution just to show streak page
          let route = (isStreak ? '/streak' : '') + '/default';
          return this.http.get(this.uriConfig.rounds + route, { params }).pipe(
            switchMap((round: GameDetailModel[] | StreakDetailModel) => {
              if (round && isArray(round) && round.length && !isStreak) {
                this.round$.next(round[0]);
              }
              return combineLatest([
                this.cmsContentService.cmsContent$,
                of (round)
              ])
            }),
            switchMap(([cmsContent, rounds, ]) => {

              if (!rounds) {
                if (!isStreak) {
                  this.modalWindowService.showRestrictedModal();
                }
                return of(null);
              }

              if (rounds && isArray(rounds) && rounds.length && !isStreak) {
                this.showUpsellOnLanding$.next(!rounds.find(round => round.status === GameStatusEnum.OPENED && !round.hasPlayed));
                this.roundsSet$.next(this.prepareRoundsSet(cmsContent, rounds));
                this.roundsIds$.next(rounds.map(round => round.id));
              } else {
                this.roundsSet$.next(this.prepareRoundForStreak(cmsContent, rounds as StreakDetailModel));
                this.round$.next({
                  ...(rounds as StreakDetailModel),
                })

                const correctAnswersAmount = (rounds as StreakDetailModel).answersStatus?.filter(answer => answer.status === HistoricalAnswerStatusEnum.Correct).length;

                this.userLeaderboardService.setUserLeaderboardData(
                  (rounds as StreakDetailModel).userLeaderboardData,
                  (rounds as StreakDetailModel).streakLevel,
                  correctAnswersAmount
                );
                this.streakService.setStreak(rounds as StreakDetailModel)
              }
              return of(Array.isArray(rounds) ? rounds[0] : rounds);
            })
          )
        })
      )
  }

  getHistoryForPreview(): Observable<SubmittedAnswersDetailsModel> {
    const answers= this.localStorageService.getPreviewAnswers();
    const userHistory = Object.values(answers).map((savedAnswer: SavedQuestionModel) => {
      const { questionText, userAnswer, questionId, answer, type: questionType,   isExternalAnswer } = savedAnswer;
      const answerId: number | null = questionType !== QuestionEnum.RANGE && !this.questionService.isScoreQuestion(questionType) ? answer as number : null;
      let submittedUserAnswer = userAnswer || answer;
      if (this.isListQuestion(questionType)) {
        submittedUserAnswer = userAnswer || answerId
      }
      if (questionType === QuestionEnum.SCORE || questionType === QuestionEnum.SCORE_PLUS) {
        submittedUserAnswer = `${(answer as ScoreQuestionAnswer).home}:${(answer as ScoreQuestionAnswer).away}`
      }
      return {
        questionId,
        questionText,
        questionType,
        userAnswer: submittedUserAnswer,
        status: HistoricalAnswerStatusEnum.Pending,
        answerId,
        isExternalAnswer }
    })
    return of({ leaderboardPosition:0, history: userHistory });
  }

  isListQuestion(type) {
    return type === QuestionEnum.LIST || type === QuestionEnum.LISTS || type === QuestionEnum.OPTIONS || type === QuestionEnum.GRID;
  }

  reloadRound() {
    this.getDefaultRounds().pipe(
      take(1)
    ).subscribe();
  }

  reloadQuestionList() {
    return combineLatest([
      this.defaultRoundId$,
      this.userService.isRestrictedUser$,
      this.isDataProcessingDisallow$,
      this.isPreviewById$,
      this.isPreviewGame$,
      this.questionService.previewQuestionId$
    ]).pipe(
      take(1),
      switchMap(([defaultRoundId, isRestrictedUser, isDataProcessingDisallow, isPreviewGameId, isPreviewGame, previewQuestionId ]) => {
        if (isRestrictedUser || isDataProcessingDisallow) {
          return of(null);
        }
        if (isPreviewGame && !isPreviewGameId) return this.questionService.questionList$;

        if (isPreviewGame && isPreviewGameId && previewQuestionId && previewQuestionId.length) {
          return this.getQuestionList(defaultRoundId, previewQuestionId);
        }

        return this.getQuestionList(defaultRoundId);
      })
    )
  }

  reloadQuestionList_v3(gameId) {
    return combineLatest([
      this.roundsSet$.pipe(take(1)),
      this.userService.isRestrictedUser$,
      this.isPreviewById$,
      this.isPreviewGame$,
      this.questionService.previewQuestionId$,
      this.tenantService.isStreak$
    ]).pipe(
      take(1),
      switchMap(([roundsSet, isRestrictedUser, isPreviewGameId, isPreviewGame, previewQuestionId, isStreak]) => {
        const hasPlayed = roundsSet[this.currentRound$.value]?.round.hasPlayed;

        if (isRestrictedUser) {
          return of(null);
        }
        if (isPreviewGame && !isPreviewGameId) return this.questionService.questionList$;

        const roundIds = isStreak ? [(roundsSet as StreakDetailModel).id] : Object.keys(roundsSet)

        if (isPreviewGame && isPreviewGameId && previewQuestionId && previewQuestionId.length) {
          return this.getQuestionList(roundIds[0], previewQuestionId);
        }
        if (hasPlayed && this.questionService.questionList$.value && this.questionService.questionList$.value[0]?.roundId === this.currentRound$.value) {
          return of(this.questionService.questionList$);
        }
        return this.getQuestionList_v3(gameId, isStreak);
      })
    )
  }

  getQuestionList(defaultRoundId, previewQuestionId?) {
    return combineLatest([
      this.questionService.getQuestionListByRoundId(defaultRoundId, previewQuestionId),
      this.tenantService.isSuperbetBrazil$,
      this.isSubmitFromPreview$,
      this.isPreviewGame$,
    ])
      .pipe(map(([questionList, isSuperbetBrazil, isSubmitFromPreview, isPreviewGame] :[QuestionDetailsModel[], boolean, boolean, boolean]) => {
        if (isSubmitFromPreview) return this.questionService.questionList$;
        if (this.currentRound$.value && this.currentRound$.value === defaultRoundId) {
          this.questionService.questionList$.next(questionList);
        }
        if (!this.currentRound$.value) {
          this.questionService.questionList$.next(questionList);
        }
        const savedPreviewQuestions = this.localStorageService.getPreviewAnswers();
        if (isPreviewGame && savedPreviewQuestions && Object.keys(savedPreviewQuestions).length && this.languageService.currentLocale$.value) {
          const answers = this.prepareAnswerHistory(savedPreviewQuestions);
          this.questionService.setAnswersFromQuestions(questionList, answers, isSuperbetBrazil, this.currentRound$.value);
        } else {
          this.questionService.setAnswersFromQuestions(questionList, null, isSuperbetBrazil, this.currentRound$.value);
        }
        return questionList;
      }))
  }
//return this.isSubmitFromPreview$.pipe(take(1)) when preview will be done
  getQuestionList_v3(defaultRoundId, isStreak = false, previewQuestionId?) {
    return combineLatest([
      this.questionService.getQuestionListByRoundId(defaultRoundId, previewQuestionId, isStreak).pipe(take(1)),
      this.tenantService.isSuperbetBrazil$.pipe(take(1)),
      this.isPreviewGame$.pipe(take(1)),
      this.cmsContentService.cmsContent$
    ])
      .pipe(take(1),
        tap(([questionList, isSuperbetBrazil, isPreviewGame, cmsContent]) => {
          const currentAnswer: QuestionItemDetailsModel = {};
          questionList.forEach((question) => {
            if (question.answers) {
              question.answers.forEach((answer) => {
                answer.text = this.questionService.getReplacedAnswerText(answer.text, cmsContent);
              })
            }
            return question;
          })
          currentAnswer[defaultRoundId] = questionList;

          const currentValue = this.questionService.roundsQuestionSet$.value;
          this.questionService.roundsQuestionSet$.next({ ...currentValue, ...currentAnswer });

          const savedPreviewQuestions = this.localStorageService.getPreviewAnswers();
          if (isPreviewGame && savedPreviewQuestions && this.languageService.currentLocale$.value) {
            const answers = this.prepareAnswerHistory(savedPreviewQuestions);
            this.questionService.setAnswersFromQuestions(questionList, answers, isSuperbetBrazil, this.currentRound$.value);
          } else {
            this.questionService.setAnswersFromQuestions(questionList, null, isSuperbetBrazil, this.currentRound$.value);
          }

          this.questionService.questionList$.next(questionList);
        }),
        map(() => this.questionService.questionList$.value))
  }
  isGameOpenAndPlayed(game) {
    return game && game.status === GameStatusEnum.OPENED && game.hasPlayed;
  }

  isGameOpenAndNotPlayed(game) {
    return game.status === GameStatusEnum.OPENED && !game.hasPlayed;
  }

  isGameCompletedAndNotPaid(game) {
    return game.status === GameStatusEnum.COMPLETED && !game.isPaymentDone && game.hasPlayed;
  }

  isGameClosedAndEventNotFinished(game) {
    return game.status === GameStatusEnum.CLOSED && !game.isSportEventFinished;
  }

  isGameClosedAndEventFinished(game) {
    return (game.status === GameStatusEnum.CLOSED && game.isSportEventFinished) || game.status === GameStatusEnum.RESULT_PROCESSING;
  }

  isGameCompletedAndWon(game) {
    return game.status === GameStatusEnum.COMPLETED && game.isPaymentDone && game.cashPoints && game.hasPlayed;
  }

  isGameCompletedAndLost(game) {
    return game.status === GameStatusEnum.COMPLETED && game.isPaymentDone && !game.cashPoints && game.hasPlayed;
  }

  isGameCompletedAndNotPlayed(game) {
    return (game.status === GameStatusEnum.COMPLETED || game.status === GameStatusEnum.CLOSED) && !game.hasPlayed;
  }


  getButtonText(game, cmsContent) {
    if (this.isGameOpenAndPlayed(game)) {
      return cmsContent.text_content['iframe-v3-landing-edit-picks-button-label'];
    }

    if (this.isGameOpenAndNotPlayed(game)) {
      return cmsContent.text_content['iframe-v3-landing-play-game-button-label'];
    }

    if (game.status === GameStatusEnum.PENDING) {
      return cmsContent.text_content['iframe-v3-landing-panding-game-button-label'];
    }

    if (this.isGameClosedAndEventNotFinished(game)) {
      return cmsContent.text_content['iframe-v3-landing-closed-game-button-label'];
    }

    if (this.isGameClosedAndEventFinished(game)) {
      return cmsContent.text_content['iframe-v3-landing-result-processing-game-button-label'];
    }

    if (this.isGameCompletedAndNotPaid(game)) {
      return cmsContent.text_content['iframe-v3-landing-view-result-button-label'];
    }

    if (this.isGameCompletedAndWon(game)) {
      return cmsContent.text_content['iframe-v3-landing-paid-out-button-label'];
    }

    if (this.isGameCompletedAndLost(game)) {
      return cmsContent.text_content['iframe-v3-landing-closed-button-label'];
    }

    return cmsContent.text_content['iframe-v3-landing-view-result-button-label'];
  }

  isStreakGameOpenAndNotPlayed(game: StreakDetailModel) {
    return game.currentRoundData && !game.currentRoundData.hasPlayed && game.currentRoundData.status === GameStatusEnum.OPENED;
  }

  isStreakGameOpenAndPlayed(game: StreakDetailModel) {
    return game.currentRoundData && game.currentRoundData.hasPlayed && game.currentRoundData.status === GameStatusEnum.OPENED;
  }

  isStreakGameCompletedAndPlayed(game: StreakDetailModel) {
    return game.currentRoundData
      && game.currentRoundData.hasPlayed
      && !game.viewedPreviousRoundResult
      && game.currentRoundData.status === GameStatusEnum.COMPLETED;
  }

  isStreakGameClosedAndNotPlayed(game: StreakDetailModel) {
    return game.currentRoundData && !game.currentRoundData.hasPlayed && (game.currentRoundData.status === GameStatusEnum.CLOSED || game.currentRoundData.status === GameStatusEnum.COMPLETED);
  }

  isStreakGameNotOpened(game: StreakDetailModel) {
    return game.status === GameStatusEnum.PENDING || game.currentRoundData && game.currentRoundData.status === GameStatusEnum.PENDING && game.status === GameStatusEnum.OPENED || !game.currentRoundData && game.status === GameStatusEnum.OPENED;
  }

  isStreakGameClosedWithoutAnswers(game: StreakDetailModel) {
    return game.currentRoundData && game.currentRoundData.status === GameStatusEnum.CLOSED && game.currentRoundData.hasPlayed && game.status !== GameStatusEnum.COMPLETED && (game.status !== GameStatusEnum.CLOSED || !game.viewedPreviousRoundResult);
  }

  isStreakGameClosed(game: StreakDetailModel) {
    return !isNull(game.viewedPreviousRoundResult) ? game.viewedPreviousRoundResult && (game.status === GameStatusEnum.CLOSED || game.status === GameStatusEnum.COMPLETED) : (game.status === GameStatusEnum.CLOSED || game.status === GameStatusEnum.COMPLETED);
  }

  getStreakButtonText(game, cmsContent) {
    if (this.isStreakGameClosed(game)) {
      return cmsContent.text_content['iframe-v3-streak-landing-game-closed-button-label'];
    }

    if (this.isStreakGameOpenAndPlayed(game)) {
      return cmsContent.text_content['iframe-v3-streak-landing-edit-picks-button-label'];
    }

    if (this.isStreakGameOpenAndNotPlayed(game)) {
      return cmsContent.text_content['iframe-v3-streak-landing-play-game-button-label'];
    }

    if (this.isStreakGameCompletedAndPlayed(game)) {
      return cmsContent.text_content['iframe-v3-streak-landing-view-results-button-label'];
    }

    if (this.isStreakGameClosedAndNotPlayed(game)) {
      return cmsContent.text_content['iframe-v3-streak-landing-round-closed-button-label'];
    }

    if (this.isStreakGameNotOpened(game)) {
      return cmsContent.text_content['iframe-v3-streak-landing-opening-soon-button-label'];
    }

    if (this.isStreakGameClosedWithoutAnswers(game)) {
      return cmsContent.text_content['iframe-v3-streak-landing-view-predictions-button-label'];
    }


    return cmsContent.text_content['iframe-v3-streak-landing-view-results-button-label'];
  }

  getStreakButtonColor(game, cmsContent) {
    if ((this.isStreakGameOpenAndPlayed(game)
      || this.isStreakGameOpenAndNotPlayed(game)
      || this.isStreakGameCompletedAndPlayed(game)) && !this.isStreakGameClosed(game)) {
      return cmsContent.colour_content['iframe-v3-streak-landing-active-button-background'];
    }
    if (this.isStreakGameClosedWithoutAnswers(game)) {
      return cmsContent.colour_content['iframe-v3-streak-landing-view-predictions-button-background']
    }

    return cmsContent.colour_content['iframe-v3-streak-landing-inactive-button-background'];
  }

  getButtonTextStreakColor(game, cmsContent) {
    if ((this.isStreakGameOpenAndPlayed(game)
      || this.isStreakGameOpenAndNotPlayed(game)
      || this.isStreakGameCompletedAndPlayed(game)) && !this.isStreakGameClosed(game)) {
      return cmsContent.colour_content['iframe-v3-streak-landing-active-button-text-color'];
    }
    if (this.isStreakGameClosedWithoutAnswers(game)) {
      return cmsContent.colour_content['iframe-v3-streak-landing-view-predictions-button-text']
    }
    return cmsContent.colour_content['iframe-v3-streak-landing-inactive-button-text-color'];

  }

  getButtonColor(game, cmsContent) {
    if (game.status === GameStatusEnum.PENDING || this.isGameCompletedAndWon(game) || this.isGameCompletedAndLost(game)) {
      return cmsContent.colour_content['iframe-v3-landing-pending-game-button-background'];
    }

    if (this.isGameOpenAndPlayed(game)) {
      return cmsContent.colour_content['iframe-v3-landing-edit-game-button-background'];
    }

    if ((this.isGameOpenAndNotPlayed(game) && this.questionService.canEditQuestions(game.sportEvent.startDate, game.class) || this.isGameCompletedAndNotPaid(game))) {
      return cmsContent.colour_content['iframe-v3-landing-play-game-button-background'];
    }

    if (this.isGameCompletedAndNotPlayed(game) || !this.questionService.canEditQuestions(game.sportEvent.startDate, game.class)) {
      return cmsContent.colour_content['iframe-v3-landing-pending-game-button-background'];
    }

    return cmsContent.colour_content['iframe-v3-landing-closed-game-button-background'];
  }

  getButtonTextColor(game, cmsContent) {
    if (this.isGameOpenAndPlayed(game) || game.status === GameStatusEnum.PENDING) {
      return cmsContent.colour_content['iframe-v3-landing-edit-game-button-color'];
    }

    if ((this.isGameOpenAndNotPlayed(game) && this.questionService.canEditQuestions(game.sportEvent.startDate, game.class) || this.isGameCompletedAndNotPaid(game))) {
      return cmsContent.colour_content['iframe-v3-landing-play-game-button-color'];
    }

    return cmsContent.colour_content['iframe-v3-landing-closed-game-button-color'];

  }

  getButtonBorderColor(game, cmsContent) {
    if (game.status === GameStatusEnum.PENDING
      || this.isGameCompletedAndWon(game)
      || this.isGameCompletedAndLost(game)
      || this.isGameCompletedAndNotPlayed(game)
      || !this.questionService.canEditQuestions(game.sportEvent.startDate, game.class)) {
      return cmsContent.colour_content['iframe-v3-landing-pending-game-button-border-color'];
    }

    return '';
  }

  prepareAnswerHistory(savedPreviewQuestions) {
    const history = Object.keys(savedPreviewQuestions).map((key) => {
      const { type: questionType, userAnswer, answer: answerId } = savedPreviewQuestions[key];
      let modifyUserAnswer = userAnswer;
      if (questionType === QuestionEnum.RANGE) {
        modifyUserAnswer = userAnswer ? userAnswer.toString() : answerId;
      }
      if (questionType === QuestionEnum.SCORE_PLUS || questionType === QuestionEnum.SCORE) {
        modifyUserAnswer = userAnswer || `${answerId.home}:${answerId.away}`;
      }
      return {
        questionType,
        userAnswer: modifyUserAnswer,
        questionId: +key,
        answerId
      }
    })
    return { history };
  }

  isGameFinished(gameStates) {
    return gameStates === GameStatusEnum.CLOSED || gameStates === GameStatusEnum.RESULT_PROCESSING || gameStates === GameStatusEnum.COMPLETED;
  }
}
