import { Injectable } from '@angular/core';
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  lastValueFrom,
  map,
  Observable,
  switchMap,
  take,
  tap
} from 'rxjs';
import { filter } from "rxjs/operators";

import {
  TeamStatisticModalComponent
} from "@app/frame-bracket/components/team-statistic-modal/team-statistic-modal.component";
import { GenerateConfirmationModalConfigService } from "@common-services/generate-confirmation-modal-config.service";
import { IsDesktopService } from "@common-services/is-desktop.services";
import { PrizeModalService } from "@common-services/prize-modal.service";
import { TenantService } from "@common-services/tenant.services";
import { ConfirmationModalComponent } from "@components/confirmation-modal/confirmation-modal.component";
import { InfoModalComponent } from "@components/info-modal/info-modal.component";
import { PrizeModalComponent } from "@components/prize-modal/prize-modal.component";
import { ConfirmationModalTypeEnum } from "@enums/ConfirmationModalTypeEnum";
import { InfoModalTabsEnum } from "@enums/InfoModalTabsEnum";
import { BracketTeamModel } from "@models/BracketTeamModel";
import { ConfirmationModalDetails } from "@models/ConfirmationModalDetails";
import { MaintenanceControlService } from "@services/maintenance-control.service";


@Injectable({ providedIn: 'root' })
export class ModalWindowService {

  modalWindow: MatDialogRef<ConfirmationModalComponent | PrizeModalComponent>;

  bracketTeamStatsModal: MatDialogRef<TeamStatisticModalComponent>;

  isPrizeModalOpened = new BehaviorSubject<boolean>(false);

  isAnyModalOpened = new BehaviorSubject<boolean>(false);

  constructor(
    private bottomSheet: MatBottomSheet,
    private maintenanceControlService: MaintenanceControlService,
    private dialog: MatDialog,
    private generateConfirmationModalConfigService: GenerateConfirmationModalConfigService,
    private tenantService: TenantService,
    private isDesktopService: IsDesktopService,
    private router: Router,
    private prizeModalService: PrizeModalService
  ) {
    this.closeModalOnMaintenance();
  }

  async closeModalOnMaintenance() {
    await lastValueFrom(this.maintenanceControlService.isMaintenance$.pipe(
      filter(isMaintenance => isMaintenance),
      tap(() => this.closeModal())
    ))
  }

  openInfoModal(selectedTab?: InfoModalTabsEnum) {
    this.tenantService.isStreak$
      .pipe(take(1))
      .subscribe(flag => {
        const panelClass = flag
          ? ['frame-v3-streak-overlay-panel']
          : ['frame-v3-overlay-panel'];
        this.bottomSheet.open(InfoModalComponent, { panelClass, data: selectedTab, autoFocus: false });
      })
  }

  closeModal(data?: any) {
    this.bottomSheet.dismiss(data)
  }

  openPrizeModal(isDesktop: boolean, data: ConfirmationModalDetails, panelClass) {
    if (this.modalWindow) {
      this.modalWindow.close();
    }
    const { disableClose } = data;
    const position = isDesktop ? { top: '11vw' } : { bottom: '0px' };
    const modalWindow = this.dialog.open(
      PrizeModalComponent,
      { data, panelClass, disableClose, autoFocus: false, position }
    );
    this.modalWindow = modalWindow;
    return modalWindow;
  }

  openConfirmModal(isDesktop: boolean, data: ConfirmationModalDetails, panelClass:string | string[] = 'confirmation-modal-v3') {
    if (this.modalWindow) {
      this.modalWindow.close();
    }
    const { disableClose } = data;
    const position = isDesktop ? { top: '11vw' } : { bottom: '0px' };
    const modalWindow = this.dialog.open(
      ConfirmationModalComponent,
      { data, panelClass, disableClose, autoFocus: false, position }
    );
    this.modalWindow = modalWindow;
    return modalWindow;
  }

  async showRestrictedModal (errorStatus: number|null = null, isNeedNavigate = false) {
    if (errorStatus && errorStatus !== 403) return;
    if (isNeedNavigate) {
      this.router.navigate(['frame']);
    }
    await firstValueFrom(this.restrictedSegmentUserModal());
  }

  bannedUserModal() {
    return combineLatest([
      this.isDesktopService.isDesktop$,
      this.tenantService.isStreak$,
      this.generateConfirmationModalConfigService.generateConfig(ConfirmationModalTypeEnum.bannedUserV3)
    ]).pipe(
      switchMap(([isDesktop, isStreak, config]) => {
        return this.openConfirmModal(isDesktop, config, isStreak ? ['banned-users-v3', 'streak'] : 'banned-users-v3').afterClosed();
      }),
    )
  }

  restrictedSegmentUserModal() {
    return combineLatest([
      this.isDesktopService.isDesktop$,
      this.tenantService.isStreak$,
      this.generateConfirmationModalConfigService.generateConfig(ConfirmationModalTypeEnum.restrictedSegmentUserV3)
    ]).pipe(
      switchMap(([isDesktop, isStreak, config]) => {
        const panelClass = ['banned-users-v3', 'segment'];
        if (isStreak) {
          panelClass.push('streak');
        }
        return this.openConfirmModal(isDesktop, config, panelClass).afterClosed();
      }),
    )
  }

  prizeModal(roundPrizeInfo) {
    this.isPrizeModalOpened.next(true);

    return combineLatest([
      this.isPrizeModalOpened,
      this.isDesktopService.isDesktop$,
      this.generateConfirmationModalConfigService.generateConfig(ConfirmationModalTypeEnum.prizeV3)
    ]).pipe(
      take(1),
      filter(([isPrizeModalOpened, isDesktop, config]) => isPrizeModalOpened),
      switchMap(([isPrizeModalOpened, isDesktop, config]): Observable<[boolean, boolean, ConfirmationModalDetails]> =>
        this.prizeModalService.getRoundPrizePopup(roundPrizeInfo.id).pipe(
          take(1),
          map(res => ([isPrizeModalOpened, isDesktop, { ...config, content: res?.content, header: res?.title ?? config.header }]))
        )
      ),
      switchMap(([isPrizeModalOpened, isDesktop, config]) => {
        const newConfig = { ...config, body: { ...roundPrizeInfo } };
        return this.openPrizeModal(isDesktop, newConfig, 'prize-modal-v3').afterClosed();
      }),
    )
  }

  signInModal() {
    return combineLatest([
      this.isDesktopService.isDesktop$,
      this.generateConfirmationModalConfigService.generateConfig(ConfirmationModalTypeEnum.signInV3)
    ]).pipe(
      switchMap(([isDesktop, config]) => {
        return this.openConfirmModal(isDesktop, config).afterClosed();
      }),
    )
  }

  openTeamStatModal(data: BracketTeamModel[]) {
    return this.isDesktopService.isDesktop$.pipe(
      switchMap((isDesktop) => {
        if (this.bracketTeamStatsModal) {
          this.bracketTeamStatsModal.close();
        }
        const position = !isDesktop && { bottom: '0px' };
        const modalWindow = this.dialog.open(
          TeamStatisticModalComponent,
          { data, disableClose: false, autoFocus: false, position }
        );
        this.bracketTeamStatsModal = modalWindow;
        return modalWindow.afterClosed();
      }),
    )
  }
}
